#toast-container {
    left: auto !important;
    top: 7% !important;
    right: 5% !important;
}
body {
    height: 100vh;
    overflow: hidden;
    background: rgb(2,0,36);
    background: linear-gradient(177deg, rgba(2,0,36,0) 0%, rgba(254,216,216,0.3) 72%, rgba(9,9,121,0) 100%);
}
* {
    padding: 0;
    margin: 0;
    color: #3d5759;
}
.shadow {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.06) !important;
}
.badge {
    white-space: nowrap;
}

.rounded {
    border-radius: 5px !important;
}

.main-color {
    background-color: #ffb2ad4a !important;
}

.modal-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    font-size: 18px;
    padding: 0 4px;
    line-height: 20px;
    cursor: pointer;
    background-color: #ea8181;
    color: #fff;
    border-radius: 3px;
}

table th,
table td:nth-child(2),
table td:nth-child(3)
{
    text-align: center;
}


.goal-popup-content {
    padding: 12px !important;
    border-radius: 5px;
}

.goal-popup-content .input-field>label {
    top: -8px;
}

.goal-popup-content input {
    padding: 0 10px !important;
    width: 95% !important;
}

.loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;
}
.loader.small {
    height: auto;
}
.loader .spinner-blue-only {
    border-color: #3d5759;
}
.loader .circle-clipper .circle {
    border-width: 8px;
}

.time {
    display: flex;
    align-items: flex-end;
    font-size: max(3vw, 20px);
    line-height: 1;
}
#minutes:before {
    content: ':';
}

.navbar .header-title {
    display: flex;
    justify-content: space-between;
}

.side-bar {
    background: white;
    padding: 10px;
    margin: 10px 0;
    max-height: 90vh;
    overflow: scroll;
    height: 100vh;
}
.collection {
    margin: .5rem 0 1px 0 !important;
}
.collection .collection-item {
    padding: 0 20px;
}
.header-title {
    font-size: 20px;
    padding: 5px 15px;
}


@media screen and (max-width: 990px) {
    body {
        overflow: scroll;
    }

    .navbar .header-title {
        flex-direction: column;
    }

    .dashboard-content {
        display: flex;
        flex-direction: column;
    }

    .row .col.s5, .row .col.s7 {
        width: 100% !important;
    }

    .side-bar {
        height: auto;
        max-height: none;
    }

    .popup-content {
        width: 50%;
    }
}