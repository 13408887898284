.title {
    font-weight: bold;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}
.new {
    background-color: #52F86A;
    color: black;
    font-size: 12px;
    margin-left: 7px;
    padding: 3px 5px;
}
.item {
    display: flex;
}
.item i {
    color: #26a69a;
    margin-right: 5px;
}

@media screen and (max-width: 990px) {
    .title {
        flex-direction: column-reverse;
    }
    .title span {
        margin-bottom: 5px;
    }
}
